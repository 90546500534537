html,
body,
#root {
  height: 100%;
  background-color: #212032;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}

.auth {
  .auth-background-holder {
    height: 100vh;
    min-height: 100%;
  }
}

.auth-main-col,
.auth-background-col {
  position: relative;
  margin-top: 40px;
  /* background-color: #009688; */
}


/* .auth-main-col {
  margin: auto;
} */

.auth-background-col {
  background: rgba();
  background-color: #ffd964;
}

.auth-option {
  font-size: 0.875rem;
  /* margin-bottom: 57px; */
}

a {
  color: #ffd964;
  text-decoration: 'none';
}

input:focus {
  box-shadow: none;
}

@media (max-width: 767.98px) {
  .auth-background-col {
    display: none;
  }
}


.paper {
  padding: 3;
  width: 400px;
}

.form {
  display: flex;
  flex-direction: column;
  grid-area: 3px;
}

.formulaire {
  display: flex;
  justify-content: center;
  margin-top: 72px;
  /* width: 400px; */
}

.img {
  height: 618px;
  width: 682px;
}

.paragraphe {
  color: #4b5563;
  text-align: left;
  font-size: 12px;
  font-family: Montserrat;
  /* font-style: normal; */
  /* font-weight: 300; */
}

.button-btn {
  color: #ffffff;
  padding: 18px;
  background-color: #9f5540 important !;
  /* height: 40px; */
  box-shadow: 0px 40px 80px 0px rgba(0, 150, 136, 0.15);
  border: none;
}

.title-connecter {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  text-align: left;
}

.form-control inputs {
  border-radius: 30px;
}

.form-label {
  color: #000;
  font-family: Montserrat;
  font-size: 12.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.form-label2 {
  color: #555;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.form-check-label {
  color: #000000;
  font-family: Montserrat;
  font-size: 12.333px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.logo-app {
  /* margin-right: 50%; */
  text-align: left;
  margin-bottom: 25px;
}

.logo-app img {
  width: 100px;
}

.side {
  margin-bottom: 23px;
}

.linker {
  margin-top: 13px;
}

.allIconImage img {
  width: 70%;
  /* Ajuste la largeur de l'image pour qu'elle occupe 100% de la largeur de la div parent */
  height: auto;
  /* Ajuste automatiquement la hauteur pour conserver les proportions de l'image */
  display: block;
  /* Supprime l'espace réservé en bas de l'image */
  margin: 0 auto;
  margin-top: 70px;
}

.allIconImage {
  display: flex;
  justify-content: center;
  /* margin-bottom: 40px; */
}

.text-bas {
  margin: 0 auto;
  width: 500px;
  margin-top: 50px;
}

.text-bas h6 {
  color: rgba(255, 255, 255, 0.6);
  font-family: Montserrat;
  /* font-size: 25.667px; */
  font-style: normal;
  font-weight: 600;
}

.text-bas p {
  color: #fff;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 56px; */
}

.text-centers {
  color: var(--Muted, #9f5540);
  font-family: Montserrat;
  font-size: 18.667px;
  font-style: normal;
  font-weight: 400;
}

.h1 {
  /* color: #009688; */
  font-family: 'Nunito Sans', 'Helvetica Neue', sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 10px;
}

.p {
  /* color: #404f5e; */
  font-family: 'Nunito Sans', 'Helvetica Neue', sans-serif;
  font-size: 20px;
  margin: 0;
}

.i {
  /* color: #009688; */
  font-size: 100px;
  line-height: 200px;
  /* margin-left:-15px; */
}

.mm {
  margin: 70px auto;
}

.MotDePasse {
  width: 40%;
}

@media (max-width: 1024px) {
  .MotDePasse {
    width: 100%;
  }
}