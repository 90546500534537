.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  background-color: "#FFFFFF" !important;
  position: fixed;
  width: 100%;
  z-index: 1000;
  height: 72px;
  border: none;
  margin: 0;
}

.navbar {
  display: flex;
  /* margin-left: 28px; */
}

.nav-item {
  margin-right: 10px;
  text-decoration: none;
  font-weight: 420;
  color: black;
  position: relative;
  line-height: 34px;
  padding-left: 10px;
  font-size: 18px;
  font-family: Roboto;
}

.nav-item.active:after {
  content: '';
  position: absolute;
  bottom: -16px;
  left: 0;
  width: 100%;
  height: 4px;
  border-bottom: 2px solid #000000;
}

.right-items {
  display: flex;
  align-items: center;
}

.search-bar {
  margin-right: 10px;
  padding: 5px;
}

.logout-button {
  padding: 8px 15px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #cc0000;
}